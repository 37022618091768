<template>
    <v-card class="white border-boxGlobal">
        <v-card-title> Ajuste de Inventario </v-card-title>

        <block v-if="loading"></block>

        <!-- Aniimate custom table-->
        <VersatileFilter :columnsSearch="columsConfig" :filterEndpoint="$API.inventoryReports.getSpecialInventorySearchReport"
            :search="search" @emptyFilter="getEarningDetail" @filtered="filterHandler" :key="Reloadfilter" :useCache="true"
            @updateCache="updateCache" :columnfilterEndpoint="$API.inventoryReports.getSpecialInventoryFilterReportColumn">
            <DxDataGrid width="100%" :data-source="reportsData" :show-borders="true"
                @exporting="onExporting" @selectionChanged="selectionChanged" :scrolling="{ useNative: true }"
                :column-auto-width="true">
                <DxExport :enabled="true" />
                <DxColumn cell-template="seeDetail" />
                <DxColumn data-field="BarCodes" caption="Códigos Barra/Alterno"></DxColumn>
                <DxColumn data-field="ItemName" caption="Nombre del Producto"></DxColumn>
                <DxColumn data-field="TotalStock" caption="Disponible"></DxColumn>
                <DxColumn data-field="ItemPriceFormatted" caption="Precio Total"></DxColumn>
                <DxColumn data-field="SuggestedPriceFormatted" caption="Último precio de compra"></DxColumn>
                <DxColumn data-field="Warehouse" caption="Almacén"></DxColumn>
                <DxColumn data-field="providersNames" caption="Proveedores" />
                <DxColumn data-field="ID_ItemBatch" caption="Lote" />
                <DxColumn data-field="LastPurchase" caption="Última Fecha de compra" />

                <template #show-providers="{ data }">
                    <v-btn class="btn-add" @click="showProvidersOfProduct(data.data)">
                        Ver proveedores
                    </v-btn>
                </template>

                <template #seeDetail="{ data }">
                    <v-btn class="btn-add" href="#" @click.prevent.stop="
                        selectedReport = data.data; form.warehouse = data.data.ID_Warehouse; form.add = true;
                    showReport = true;
                    "><v-icon color="white" class="mr-2">mdi-package-variant-closed</v-icon>Hacer Ajuste
                    </v-btn>
                </template>

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />

                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>
        <!-- Aniimate custom table-->

        <v-dialog v-model="showReport" max-width="80%" scrollable>
            <v-card class="border" style="max-height: inherit;overflow-y: scroll;">
                <v-btn @click="showReport= false" elevation="0" color="red" class="close-btn"><v-icon>mdi-close</v-icon></v-btn>
                <div v-if="selectedReport" class="pa-10">
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker label="Fecha Ajuste" @dateSelected="changueDt" v-model="form.date"
                                :value="form.date" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3>Detalle de Producto</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>ID Producto: </strong>
                                {{ selectedReport.ID_Item }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Nombre: </strong>
                                {{ selectedReport.ItemName }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Almacén: </strong>
                                {{ form.warehouse ? form.warehouse : selectedReport.Warehouse }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Unidad: </strong>
                                {{ selectedReport.ItemMeasureUnit }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Lote: </strong>
                                {{ selectedReport.ID_ItemBatch ? selectedReport.ID_ItemBatch : "Por defecto" }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Último precio ingresado: </strong>
                                {{
                                    selectedReport.LastPrice
                                }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3>Parametros de ingreso</h3>
                        </v-col>
                        <v-col cols="12" md="4">

                            <v-radio-group v-model="form.add" row>
                                <v-radio label="Agregar existencia" :value="true"></v-radio>
                                <v-radio label="Restar existencia" :value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="form.quantity" label="Cantidad" outlined dense type="number" />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="form.price" label="Costo de Compra" outlined dense />
                        </v-col>

                    </v-row>
                    <v-row>


                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="form.lote" label="Lote" outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-select v-model="form.warehouse" label="Almacén" :items="datafilter.Warehouses"
                                item-text="name" item-value="value" attach outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-btn @click="showComments = !showComments" :class="form.comment ? 'btnclean' : 'btnblack'">
                                Comentarios
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Actuales: " + selectedReport.TotalStock }}
                            </h2>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Nuevas: " + totalStock }}
                            </h2>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="mt-3">
                    <v-btn @click="showReport = false" class="btndelete">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btnsave" :disabled="!form.quantity || !form.date" @click="
                        showReport = false;
                    sendAdjustment();
                    ">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showComments" max-width="80%">
            <v-card >
                <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
                    <v-btn small class="btndelete" @click.prevent="showComments = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-col cols="12" md="12" style="">
                    <quill-editor v-model="form.comment"></quill-editor>
                </v-col>
            </v-card>
        </v-dialog>


        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxExport,
} from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
import VersatileFilter from "@/components/VersatileFilter";
//import GeneralFilter from  "@/components/GeneralFilter";
import Block from "@/components/Block";
import DatePicker from "@/components/DatePicker";
import Alerts from "@/components/Alerts";

export default {
    name: "SpecialInventoryPhysicalR",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxPager,
        DxExport,
        DxPaging,
        //  GeneralFilter,
        // DxSelection,
        DatePicker,
        Alerts,
        VersatileFilter,
    },
    props: ["win", "datafilter"],
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            loading: false,
            panel: 0,
            reportsData: [],
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {},
            showFilter: false,
            Reloadfilter: 0,
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "idproviders",
                    title: "Proveedores",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_category",
                    title: "Categoría",
                    value: "",
                    parent: 'Id_family',
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_subcategory",
                    title: "Subcategoría",
                    value: "",
                    parent: 'Id_category',
                    type: "select",
                    options: [],
                },
                {
                    name: "ID_ItemBatch",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            cacheData: [],
            selectedReport: null,
            form: {},
            showComments: false,
            showReport: false,
            warehouses: [],
            Currency: JSON.parse(localStorage.getItem('branch')).Currency,
        };
    },
    computed: {
        totalStock() {
            if (this.selectedReport.TotalStock && this.form.quantity && !this.form.add) {
                return this.selectedReport.TotalStock - this.form.quantity;
            } else if (this.selectedReport.TotalStock && this.form.quantity && this.form.add) {
                return this.selectedReport.TotalStock + +this.form.quantity;
            } else if (this.selectedReport.TotalStock) {
                return this.selectedReport.TotalStock;
            }
            else if ((this.selectedReport.TotalStock == null || this.selectedReport.TotalStock == undefined || this.selectedReport.TotalStock == 0) && this.form.quantity) {
                return this.form.add ? this.form.quantity : -this.form.quantity;
            }
            return 0;
        },
    },
    methods: {
        closeAlert() {
            this.alert.show = false;

            if (this.alert.options == "reload") {
                this.getEarningDetail();
            }
            else if (this.alert.options == "reloadwithalert") {
                console.log('recargando');
                this.reloadDatawithParams();

            }
        },
        acceptAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        reloadDatawithParams() {

            this.$API.inventoryReports.getSpecialInventoryFilterReportColumn(this.cacheData).then(response => {
                this.reportsData = response;
            })
            .finally(() => {
                this.setReportCurrency();
            });
        },
        sendAdjustment() {

            if (this.form.warehouse == null) {
                return this.showAlert(
                    "warning",
                    "Ajuste de inventario",
                    "Seleccione un almacén"
                );
            }
            if (this.totalStock < 0) {
                return this.showAlert(
                    "warning",
                    "Ajuste de inventario",
                    "No se puede realizar un ajuste negativo"
                );
            }
            this.loading = true;
            this.$API.inventoryReports
                .sendAdjusmten({
                    item: this.selectedReport,
                    adjustmentParams: this.form,
                })
                .then((response) => {
                    this.loading = false;
                    this.showAlert(
                        "success",
                        "Ajuste de inventario",
                        "Ajuste realizado correctamente",
                        "reloadwithalert"
                    );
                    console.log(response);
                    this.form = {};
                    this.selectedReport = null;
                })
                .finally(() => {
                    this.setReportCurrency();
                });
        },
        updateCache(data) {
            console.log('actualizando cache');
            this.cacheData = data;
        },
        selectionChanged(e) {
            var selectedRows = e.selectedRowsData;
            if (selectedRows.length > 0) {
                this.selectedReport = selectedRows[0];
            }
        },

        getEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getSpecialInventoryReport()
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                    this.selectedReport = null;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                })
                .finally(() => {
                    this.setReportCurrency();
                });
        },
        onExporting(e) {
            printToExcel(e, "Reporte Fisico");
        },
        filterHandler(response) {
            this.reportsData = response;
            this.setReportCurrency();
        },
        changueDt(date) {
            this.form.date = date;
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.providers;
                this.columsConfig[2].options = this.datafilter.Families;
                this.columsConfig[3].options = this.datafilter.Categories;
                this.columsConfig[4].options = this.datafilter.SubCategories;
                this.columsConfig[5].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
        setReportCurrency() {
            if (this.reportsData.length > 0) {
                this.reportsData.map((product) => {
                    product.ItemPriceFormatted = `${this.Currency} ${parseFloat(product.ItemPrice).toFixed(2)}`
                    product.SuggestedPriceFormatted = `${this.Currency} ${parseFloat(product.Suggested_price).toFixed(2)}`
                })
            }
        },

        showBarCode(data) {
            let product;
            this.$API.products.getProductByID(data)
                .then((response) => {
                    product = response[0];
                })
                .finally(() => {
                    if (product) {
                        this.$store.dispatch('addWindow', {
                            name: 'ManageProduct',
                            component: 'ManageProduct',
                            unique: false,
                            meta: { product, openDialogBarCode: true }
                        })
                    }
                })

        }
    },
    mounted() {
        this.assignParams();
        this.getEarningDetail(true);

    },
};
</script>

<style scoped>
.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.close-btn {
    color: white !important;
    position: relative !important;
    float: right !important;
}

.border {
  border-radius: 15px !important;
}
</style>
