<template>
    <v-card class="white border-boxGlobal">
        <v-card-title> Reporte de existencias </v-card-title>

        <block v-if="loading"></block>
        
        <!-- Aniimate custom table-->
        <VersatileFilter :columnsSearch="columsConfig" :search="search" :filterEndpoint="$API.inventoryReports.getSpecialInventorySearchReport"
         @emptyFilter="getEarningDetail"  @filtered="filterHandler" :key="Reloadfilter"
            :columnfilterEndpoint="$API.inventoryReports.getSpecialInventoryFilterReportColumn">
            <DxDataGrid  :data-source="reportsData" :show-borders="true"
                @exporting="onExporting" :scrolling="{ useNative: true }" :column-auto-width="true">
                <DxExport :enabled="true" />
                <DxColumn data-field="BarCodes" caption="Códigos Barra/Alterno"></DxColumn>
                <DxColumn data-field="ItemName" caption="Nombre del Producto"></DxColumn>
                <DxColumn data-field="TotalStock" caption="Disponible"></DxColumn>
                <DxColumn data-field="ItemPriceFormatted" caption="Precio Total"></DxColumn>
                <DxColumn data-field="SuggestedPriceFormatted" caption="Último precio de compra"></DxColumn>
                <DxColumn data-field="Warehouse" caption="Almacén"></DxColumn>
                <DxColumn data-field="providersNames" caption="Proveedores" />
                <DxColumn data-field="ID_ItemBatch" caption="Lote" />
                <DxColumn data-field="LastPurchase" caption="Última Fecha de compra" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>
        <!-- Aniimate custom table-->

    </v-card>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxExport,
    DxPager,
} from "devextreme-vue/data-grid";
import VersatileFilter from "@/components/VersatileFilter";
import { printToExcel } from "@/helpers/printToexcel";
import Block from "@/components/Block";

export default {
    name: "SpecialInventoryReport",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxPager,
        VersatileFilter,
        DxExport,
        DxPaging,
    },
    props: ["win", "datafilter"],
    data() {
        return {
            loading: false,
            panel: 0,
            reportsData: [],
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {
                filter: null
            },
            Reloadfilter: 0,
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "idproviders",
                    title: "Proveedores",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_category",
                    title: "Categoría",
                    value: '',
                    parent: 'Id_family',
                    type: "select",
                    options: [],
                },
                {
                    name: "Id_subcategory",
                    parent: 'Id_category',
                    title: "Subcategoría",
                    value: '',
                    type: "select",
                    options: [],
                },
                {
                    name: "ID_ItemBatch",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            Currency: JSON.parse(localStorage.getItem('branch')).Currency,
        };
    },
    methods: {
        getEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getSpecialInventoryReport()
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                    console.log(this.reportsData)
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                })
                .finally(() => {
                    this.setReportCurrency();
                });
        },
        onExporting(e) {
            printToExcel(e, "Reporte Inventarios");
        },
        filterHandler(response) {
            this.reportsData = response;
            this.setReportCurrency();
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.providers;
                this.columsConfig[2].options = this.datafilter.Families;
                this.columsConfig[3].options = this.datafilter.Categories;
                this.columsConfig[4].options = this.datafilter.SubCategories;
                this.columsConfig[5].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
        setReportCurrency(){
            if(this.reportsData.length > 0){
                this.reportsData.map((product) => {
                    product.ItemPriceFormatted = `${this.Currency} ${parseFloat(product.ItemPrice).toFixed(2)}`
                    product.SuggestedPriceFormatted = `${this.Currency} ${parseFloat(product.Suggested_price).toFixed(2)}`
                })
            }
        },
    },
    mounted() {
        this.assignParams();
        this.getEarningDetail();
    },
};
</script>

<style scoped></style>
